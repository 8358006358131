<template>
    <base-form models-name="clienti"
               :tabs="tabs"
               :new-model="newModel"
               :is-resouces-loading="isLoadingWorkspace"
               @endLoadModel="endLoadModel"
               @onSave="onSave"
    >

        <template #info="{model, isGlobalLoading}">
            <h-row justify="center">

                <h-col class="px-0" cols="9">
                    <base-select-field v-model="model.DISATTIVAZIONE_MANUALE"
                                       :items="[ {label: 'ATTIVO', value: false}, {label: 'DISATTIVATO', value: true} ]"
                                       item-text="label"
                                       item-value="value"
                                       label="Stato"
                                       placeholder="Seleziona se abilitare o disabilitare"
                                       hint="Decidi se abilitare o disabilitare manualmente il cliente"
                                       :disabled="!!isGlobalLoading || !$store.state.authModule.utente.CAN_ENABLE_CLIENTE"
                                       prepend-icon="fa-toggle-on"
                    ></base-select-field>
                </h-col>

                <h-col v-if="$store.getters.isSystem" class="px-0" cols="9">
                    <base-form-field-api-select models-name="workspace"
                                                v-model="model.ID_WORKSPACE_FK"
                                                item-text="NOME"
                                                item-value="ID_REC"
                                                label="Workspace"
                                                placeholder="Seleziona un workspace"
                                                hint="Il workspace è essenziale e non sarà più modificabile"
                                                :disabled="!!isGlobalLoading || !!model.ID_REC"
                                                prepend-icon="fa-laptop-house"
                                                :rules="[v => !!v || 'Il workspace è obbligatorio!']"
                                                @finish="isLoadingWorkspace = false"
                    ></base-form-field-api-select>
                </h-col>

                <h-col class="px-0" cols="9">
                    <base-text-field v-model.trim="model.NOME"
                                     label="Nome"
                                     placeholder="Inserisci il nome identificativo del cliente"
                                     hint="Il nome serve per riconoscere il cliente"
                                     maxlength="500"
                                     :disabled="!!isGlobalLoading"
                                     :rules="[v => !!v || 'Il nome è obbligatorio!']"
                                     prepend-icon="fa-signature"
                    ></base-text-field>
                </h-col>

                <h-col class="px-0 mb-4" cols="9">

                    <button-tooltip block
                                    color="warning"
                                    text="AGGIUNGI REFERENTE"
                                    tooltip="Click per aggiungere un nuovo referente"
                                    @click.stop="model.referenti.push({}); panelReferenti = model.referenti.length-1"
                    ></button-tooltip>

                    <v-expansion-panels v-model="panelReferenti">
                        <v-expansion-panel v-for="(ref, index) in model.referenti" :key="index">

                            <v-expansion-panel-header>
                                <h-row>
                                    <h-col>
                                        <i>Referente N° {{ index + 1 }}</i>
                                    </h-col>
                                </h-row>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                                <h-row>

                                    <h-col class="mb-6 pb-4" cols="12" no-padding>
                                        <button-tooltip block
                                                        color="error"
                                                        text="ELIMINA"
                                                        :tooltip="'Click per eliminare il referente N° ' + (index + 1)"
                                                        @click.stop="model.referenti = model.referenti.filter((r, i) => i !== index ); panelReferenti = null"
                                        ></button-tooltip>
                                    </h-col>

                                    <h-col class="mb-4" cols="12" no-padding>
                                        <base-text-field v-model.trim="ref.NOME"
                                                         label="Nome"
                                                         placeholder="Digita il nome del referente"
                                                         hint="Il nome è importante per riconoscere il referente"
                                                         maxlength="50"
                                                         :disabled="!!isGlobalLoading"
                                                         :rules="[v => !!v || 'Il nome del referente ' + (index+1) + ' è obbligatorio!']"
                                                         prepend-icon="fa-signature"
                                        ></base-text-field>
                                    </h-col>

                                    <h-col class="mb-4" cols="12" no-padding>
                                        <base-text-field v-model.trim="ref.EMAIL"
                                                         label="Email"
                                                         placeholder="Digita la mail del referente"
                                                         hint="L'email non è obbligatoria ma può essere utile"
                                                         maxlength="500"
                                                         :disabled="!!isGlobalLoading"
                                                         :rules="[v => !v  || $store.getters.emailRegExp.test(v) || 'L\'email del referente ' + (index+1) + ' deve essere valida']"
                                                         prepend-icon="fa-envelope"
                                        ></base-text-field>
                                    </h-col>

                                    <h-col class="mb-4" cols="12" no-padding>
                                        <base-text-field v-model.trim="ref.TELEFONO"
                                                         label="Telefono"
                                                         placeholder="Digita il telefono del referente"
                                                         hint="Il telefono non è obbligatoria ma può essere utile"
                                                         maxlength="500"
                                                         :disabled="!!isGlobalLoading"
                                                         :rules="[v => !v  || /^\d+$/.test(v) || 'Il telefono del referente ' + (index+1) + ' deve essere un numero valido']"
                                                         prepend-icon="fa-phone"
                                        ></base-text-field>
                                    </h-col>

                                    <h-col class="mb-4" cols="12" no-padding>
                                        <base-text-field v-model.trim="ref.FAX"
                                                         label="Fax"
                                                         placeholder="Digita il fax del referente"
                                                         hint="Il fax non è obbligatoria ma può essere utile"
                                                         maxlength="500"
                                                         :disabled="!!isGlobalLoading"
                                                         :rules="[v => !v  || /^\d+$/.test(v) || 'Il fax del referente ' + (index+1) + ' deve essere un numero valido']"
                                                         prepend-icon="fa-fax"
                                        ></base-text-field>
                                    </h-col>

                                    <h-col class="mb-4" cols="12" no-padding>
                                        <base-text-area-field v-model.trim="ref.NOTE"
                                                              label="Note"
                                                              placeholder="Digita le note del referente"
                                                              hint="Le note non sono obbligatorie"
                                                              maxlength="500"
                                                              :disabled="!!isGlobalLoading"
                                                              prepend-icon="fa-clipboard"
                                        ></base-text-area-field>
                                    </h-col>

                                </h-row>
                            </v-expansion-panel-content>

                        </v-expansion-panel>
                    </v-expansion-panels>
                </h-col>

            </h-row>
        </template>

        <template #citta="{model, form, isGlobalLoading}">
            <h-row justify="center">

                <h-col class="px-0" cols="9">

                    <base-form-field-api-autocomplete models-name="citta"
                                                      v-model="model.zCitta"
                                                      label="Citta"
                                                      item-text="ID_REC"
                                                      placeholder="Digita per iniziare la ricerca"
                                                      disable-disabled
                                                      prepend-icon="fa-university"
                                                      filter-start="DENOMINAZIONE"
                                                      @input="model.zID_CITTA_FK = !!model.zCitta ? model.zCitta.ID_REC : null"
                    >
                        <template #item="{ item }">
                            {{ getDenominazione(item) }} {{ item.SIGLA }}
                        </template>
                        <template #selection="{ item }">
                            {{ getDenominazione(item) }} {{ item.SIGLA }}
                        </template>
                    </base-form-field-api-autocomplete>

                    <button-tooltip block
                                    color="warning"
                                    text="AGGIUNGI LIMITE CITTA"
                                    tooltip="Click per aggiungere una nuovo limite per la città selezionata"
                                    :disabled="cittàExists(model)"
                                    @click.stop="model.citta.push({ID_CITTA_FK: model.zID_CITTA_FK, DISTANZA: 50, testi: model.zCitta.testi}); panelCitta = model.citta.length-1"
                    ></button-tooltip>

                </h-col>

                <h-col class="px-0" cols="9">

                    <v-expansion-panels v-model="panelCitta">
                        <v-expansion-panel v-for="(ct, index) in model.citta" :key="index">

                            <v-expansion-panel-header>
                                <h-row>
                                    <h-col cols="8">
                                        <span>{{ getDenominazione(ct) }} ({{ ct.DISTANZA }} km)</span>
                                    </h-col>
                                </h-row>
                            </v-expansion-panel-header>

                            <v-expansion-panel-content>
                                <h-row>

                                    <h-col class="mb-6 pb-4" cols="12" no-padding>
                                        <button-tooltip block
                                                        color="error"
                                                        text="ELIMINA"
                                                        :tooltip="'Click per eliminare la citta ' + getDenominazione(ct)"
                                                        @click.stop="model.citta = model.citta.filter((r, i) => i !== index ); panelCitta = null"
                                        ></button-tooltip>
                                    </h-col>

                                    <h-col class="mb-4" cols="12" no-padding>
                                        <base-text-field v-model.trim="ct.DISTANZA"
                                                         label="Distanza"
                                                         placeholder="Digita la distanza massima consentita"
                                                         hint="La distanza massima consentita parte dal centro della città"
                                                         maxlength="500"
                                                         :disabled="!!isGlobalLoading"
                                                         :rules="[v => !!v || 'La distanza del limite della città ' + (index+1) + ' è obbligatoria!',
                                                                  v => !v || /^(\d*\.)?\d+$/.test(v) || 'Il valore deve essere un decimale positivo!']"
                                                         prepend-icon="fa-people-arrows"
                                                         @input="form.validate();"
                                        ></base-text-field>
                                    </h-col>

                                </h-row>
                            </v-expansion-panel-content>

                        </v-expansion-panel>
                    </v-expansion-panels>

                </h-col>

            </h-row>
        </template>

    </base-form>
</template>

<script>
    import HRow from '@/components/aaaGenerics/layout/HRow';
    import HCol from '@/components/aaaGenerics/layout/HCol';
    import BaseForm from '@/components/aaaProject/form/BaseForm';
    import BaseFormFieldApiSelect from '@/components/aaaProject/form/BaseFormFieldApiSelect';
    import BaseFormFieldApiAutocomplete from '@/components/aaaProject/form/BaseFormFieldApiAutocomplete';
    import BaseSelectField from '@/components/aaaGenerics/form/BaseSelectField';
    import BaseTextField from '@/components/aaaGenerics/form/BaseTextField';
    import ButtonTooltip from "@/components/aaaGenerics/commons/ButtonTooltip";
    import BaseTextAreaField from '@/components/aaaGenerics/form/BaseTextAreaField';

    export default {
        name: 'FormCliente',
        components: {
            HRow,
            HCol,
            BaseForm,
            BaseFormFieldApiSelect,
            BaseFormFieldApiAutocomplete,
            BaseSelectField,
            BaseTextField,
            ButtonTooltip,
            BaseTextAreaField
        },
        data: (_this) => ({
            isLoadingWorkspace: !!_this.$store.getters.isSystem,
            panelReferenti: null,
            panelCitta: 0,
            tabs: [
                {name: 'info', title: 'INFO DI BASE', icon: 'fa-cube', showIf: true},
                {name: 'citta', title: 'LIMITI CITTA', icon: 'fa-university', showIf: true}
            ],
            newModel: {
                DISATTIVAZIONE_MANUALE: !_this.$store.state.authModule.utente.CAN_ENABLE_CLIENTE,
                referenti: [],
                citta: []
            }
        }),
        methods: {
            endLoadModel(model) {

                const referenti = model.referenti;
                model.referenti = [];
                for (const r of referenti) {
                    model.referenti.push({...r});
                }

            },
            onSave({model, save}) {
                save(model);
            },
            getDenominazione(citta) {

                let testo = citta.testi.find(t => t.ID_LINGUA_FK === this.$store.state.authModule.utente.ID_LINGUA_FK);

                if (!testo) {
                    testo = citta.testi.find(t => !!t.LNG_IS_DEFAULT);
                }

                if (!testo) {
                    testo = citta.testi[0];
                }

                return testo.DENOMINAZIONE;

            },
            cittàExists(model) {

                if (!model.zID_CITTA_FK) {
                    return true;
                }

                for (const ct of model.citta) {

                    if (ct.ID_CITTA_FK === model.zID_CITTA_FK) {
                        return true;
                    }

                }

                return false;

            }
        }
    }
</script>

<style scoped>

</style>
